<template>
  <div>
    <div class="col-xl-12 px-0">
      <!--start::TOPFORM-->
      <Topform
        ref="poll-topform"
        :managementType="managementType"
        pageType="poll"
        :name="activityInfo.title"
        :errors="activityErrors"
        :submissionStates="submissionStates"
        parentPage="/project/activities/view"
        :tabs="computedTabs"
        :validation="computedValidation"
        :loadingComplete="loadingComplete"
        @clear_errors="clearErrors()"
        @asset_reservation_created="form.stimuli.push($event)"
        @asset_reservation_deleted="
          form.stimuli = form.stimuli.filter(function(stim) {
            return stim !== $event;
          })"
        @remove_project_asset="form.stimuli_remove.push($event)"
        @clear_existing_data="clearData"
        @submit="onSubmit(null)"
      >
      </Topform>
      <!--end::TOPFORM-->
    </div>
  </div>
</template>

<script>
import { required, minLength } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import PageLoader from "@/core/classes/PageLoader.js";
import Toast from "@/core/classes/Toast.js";
import ManageActivityHelper from "@/helpers/ManageActivityHelper";

//Vuex Imports
import {
  CREATE_PROJECT_ACTIVITY,
  UPDATE_PROJECT_ACTIVITY,
  CLEAR_ACTIVITY_ERRORS,
  POPULATE_ACTIVITY_INFO,
  CLEAR_ACTIVITY_INFO
} from "@/core/services/store/activity/activity.module";
import { POPULATE_PROJECT_SEGMENTATION_LIST } from "@/core/services/store/project/project_segmentations.module";

//Topform Imports
import TopformConfig from "@/core/config/topform/ManagePoll.js";

export default {
  name: "ManagePoll",

  data() {
    return {
      pageLoader: new PageLoader(1),
      toast: new Toast(),
      submissionStates: {
        submitting: false
      },
      error: {
        active: false,
        message: ""
      },
      form: {
        poll_question: null,
        poll_answer_type: 2,
        isPollActivity: true,
        questions_id: null,
        system_activity_type_id: 9,
        poll_question_choices: [{ choice: "", project_segment_id: null }],
        stimuli: [],
        stimuli_remove: []
      }
    };
  },

  validations: {
    form: {
      poll_question: { required },
      poll_answer_type: { required },
      poll_question_choices: {
        required,
        minLength: minLength(2),
        $each: {
          choice: {
            required
          }
        }
      }
    }
  },

  mounted() {
    this.clearErrors();
    if (this.isEditActivity) {
      this.pageLoader.addRequiredComponent();
      this.fetchEditActivityInfo();
    }
    this.fetchProjectSegmentations();
  },

  methods: {
    fetchEditActivityInfo() {
      let payload = {
        projectId: this.projectInfo.id,
        activityId: this.$route.params.activity,
        params: {
          activityPollQuestion: "with",
          projectSegments: "with",
          pollQuestionChoices: "with",
          stimulusAssets: "with",
          appendSignedUrls: 1
        }
      };

      this.$store
        .dispatch(POPULATE_ACTIVITY_INFO, payload)
        .then(() => {
          this.setEditActivityInfo();
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the activity information, please check back later or contact the helpdesk";
        });
    },
    setEditActivityInfo() {
      if (this.activityInfo.activity_poll_question !== undefined) {
        let que = this.activityInfo.activity_poll_question;
        this.form.poll_question = que.question;
        this.form.questions_id = que.id;
        this.form.poll_answer_type = que.system_answer_type_id;

        if (this.activityInfo.poll_question_choices !== undefined) {
          let cho = this.activityInfo.poll_question_choices;
          if (cho.length > 0) {
            let choices = [];
            cho.forEach(choice => {
              if (choice.activity_poll_question_id == que.id) {
                choices.push({
                  choice: choice.choice,
                  id: choice.id,
                  project_segment_id: choice.project_segment_id
                });
              }
            });
            this.form.poll_question_choices = choices;
          }
        }
      }

      this.pageLoader.setCompleteComponent();
    },
    fetchProjectSegmentations() {
      let payload = {
        projectId: this.projectInfo.id,
        params: {
          projectSegments: "with",
          paginate: false
        }
      };
      this.$store
        .dispatch(POPULATE_PROJECT_SEGMENTATION_LIST, payload)
        .then(() => {
          this.pageLoader.setCompleteComponent();
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the project segmentations list, please check back later or contact the helpdesk";
        });
    },
    getPayload() {
      let payload = {
        projectId: this.projectInfo.id,
        data: this.form
      };
      if (this.isEditActivity) {
        payload.activityId = this.$route.params.activity;
      }

      let poll_question_choices = [];
      payload.data.poll_question_choices.forEach(choices => {
        if (choices.choice !== "") {
          poll_question_choices.push(choices);
        }
      });
      if (poll_question_choices !== []) {
        payload.data.poll_question_choices = poll_question_choices;
      }
      let poll_question_choices_remove = [];
      if (this.isEditActivity) {
        this.activityInfo.poll_question_choices.forEach(ext => {
          let index = payload.data.poll_question_choices.find(
            obj => obj.id === ext.id && obj.choices === ext.choices
          );
          if (index === undefined) {
            poll_question_choices_remove.push(ext);
          }
        });
      }
      if (poll_question_choices_remove !== []) {
        payload.data.poll_question_choices_remove = poll_question_choices_remove;
      }

      return payload;
    },
    onSubmit() {
      this.clearErrors();

      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      this.submissionStates.submitting = true;

      let payload = this.getPayload();

      this.$store
        .dispatch(
          this.isEditActivity
            ? UPDATE_PROJECT_ACTIVITY
            : CREATE_PROJECT_ACTIVITY,
          payload
        )
        .then(() => {
          this.makeToast("success");
          this.$router.push({ name: "project_activities" });
        })
        .finally(() => {
          this.submissionStates.submitting = false;
          this.$v.form.$reset();
        });
    },
    makeToast(variant = null) {
      let strippedQuestion = this.form.poll_question.replace(/(<([^>]+)>)/gi, "");
      
      if (this.isEditActivity) {
        this.toast.notify(this, strippedQuestion + ' ' + `${this.$t("TOASTS.EDIT_ACTIVITY.MESSAGE")}`, `${this.$t("TOASTS.EDIT_ACTIVITY.TITLE")}`, variant);
      } else {
        this.toast.notify(this, strippedQuestion + ' ' + `${this.$t("TOASTS.ADD_ACTIVITY.MESSAGE")}`, `${this.$t("TOASTS.ADD_ACTIVITY.TITLE")}`, variant);
      };
    },
    clearErrors: function() {
      this.$store.dispatch(CLEAR_ACTIVITY_ERRORS);
    },
    clearData: function() {
      this.$store.dispatch(CLEAR_ACTIVITY_INFO);
    }
  },

  computed: {
    ...mapGetters([
      "projectInfo",
      "projectSegmentations",
      "projectRoomSetupList",
      "activityErrors",
      "activityInfo",
      "systemActivityTypes"
    ]),
    assetReservationData: function() {
      let data = {
        scope: "activity",
        data: {
          directory: "poll_stimuli",
          projectId: this.projectInfo.id,
          new: 1
        }
      };
      return data;
    },
    loadingComplete: function() {
      return this.pageLoader.getStatus();
    },
    managementType: function() {
      if (this.isEditActivity) {
        return "update";
      } else {
        return "post"
      }
    },
    tab1Errors: function() {
      if (
        this.$v.form.poll_answer_type.$anyError ||
        this.$v.form.poll_question.$anyError
      ) {
        return true;
      } else {
        return false;
      }
    },
    tab2Errors: function() {
      return false;
    },
    isSequencedActivity: function() {
      return ManageActivityHelper.sequencedActivity(
        this.$route.params.activity
      );
    },
    sequenceableActivity: function() {
      return ManageActivityHelper.sequenceableActivity(
        this.systemActivityTypes,
        this.form.system_activity_type_id
      );
    },
    isEditActivity: function() {
      var routeName = this.$route.name;
      var pageType = routeName.replace("_poll", "");
      return ManageActivityHelper.editingActivity(
        this.$route.params.activity,
        pageType
      );
    },
    computedValidation: function() {
      return this.$v.form;
    },
    computedTabs: function() {
      if (this.loadingComplete) {
        return TopformConfig.topform(this);
      } else {
        return [];
      }
    },
  }
};
</script>
