class ManagePoll {

  //Suggests a key based on either a client or project name
  static topform(component) {
      return [
        {
          id: 1,
          name: `${component.$t("FORMS.INPUTS.ACTIVITY_GENERAL")}`,
          errors: component.tab1Errors,
          sections: [
            {
              rows: [
                {
                  components: [
                    {
                      name: "InputGenericSelect",
                      properties: {
                        class: "col-xl-4 px-5 mb-0",
                        id: 'input-question-answer-type',
                        label: `${component.$t("FORMS.INPUTS.ACTIVITY_POLL_ANSWER_TYPE")}`,
                        error: `${component.$t("FORMS.ERROR.ACTIVITY_POLL_ANSWER_TYPE")}`,
                        model: component.$v.form.poll_answer_type,
                        submissionStates: component.submissionStates,
                        items: [
                          {
                            value: 2,
                            text: `${component.$t('FORMS.INPUTS.ACTIVITY_ANSWER_TYPE_MC')}`
                          },
                          {
                            value: 3,
                            text: `${component.$t('FORMS.INPUTS.ACTIVITY_ANSWER_TYPE_SC')}`
                          }
                        ]
                      }
                    }
                  ]
                },
                {
                  components: [
                    {
                      name: "InputGenericRichText",
                      properties: {
                        class: "col-xl-12 px-5 mb-0",
                        id: 'input-question',
                        label: `${component.$t("FORMS.INPUTS.ACTIVITY_POLL_QUESTION_TEXT")}`,
                        model: component.$v.form.poll_question,
                        submissionStates: component.submissionStates,
                        error: `${component.$t("FORMS.ERROR.ACTIVITY_POLL_QUESTION_TEXT")}`,
                      }
                    }
                  ]
                }
              ]
            },
            {
              title: `${component.$t("FORMS.SECTION_HEADERS.ACTIVITY_STIMULI")}`,
              rows: [
                {
                  components: [
                    {
                      name: "IntegratedDropzone",
                      on: {
                        asset_reservation_created: (value) => { component.$refs["poll-topform"].$emit('asset_reservation_created', value) },
                        asset_reservation_deleted: (value) => { component.$refs["poll-topform"].$emit('asset_reservation_deleted', value) },
                        remove_project_asset: (value) => { component.$refs["poll-topform"].$emit('remove_project_asset', value) },
                      },
                      properties: {
                        class: "col-xl-12 px-3 mb-0",
                        assetReservation: component.assetReservationData,
                        idx: 1,
                        projectAssets: component.activityInfo.stimulus_assets,
                        wholeWidth: true,
                        removable: true
                      }
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          id: 2,
          name: `${component.$t("FORMS.INPUTS.ACTIVITY_CHOICES")}`,
          errors: component.tab2Errors,
          sections: [
            {
              rows: [
                {
                  components: [
                    {
                      name: "InputActivityQuestionChoices",
                      properties: {
                        class: "col-xl-12 px-5 mb-10",
                        id: 'input-question-choices',
                        model: component.$v.form.poll_question_choices,
                        segments: component.projectSegmentations,
                        systemAnswerTypeId: component.form.poll_answer_type,
                        projectId: component.projectInfo.id,
                        activityId: component.activityInfo.id,
                        submissionStates: component.submissionStates,
                        noStimuli: true,
                        error: `${component.$t("FORMS.ERROR.POLL_QUESTION_CHOICES")}`,
                      }
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  }
  
export default ManagePoll;